export const enrichResponseOrder = [
  {
    name: 'recordID',
    title: 'Record ID',
  },
  {
    name: 'recordStatus',
    title: 'Record Status',
  },
  {
    name: 'accuracy',
    title: 'Accuracy',
  },
  {
    name: 'recordConfidenceScore%',
    title: 'Record Confidence Score%',
  },
  {
    name: 'compGUID',
    title: 'Company GUID',
  },
  {
    name: 'compName',
    title: 'Company Name',
  },
  {
    name: 'compNameAka',
    title: 'Company Name AKA',
  },
  {
    name: 'compPhoneNo',
    title: 'Company Phone',
  },
  {
    name: 'compAddr',
    title: 'Company Address',
  },
  {
    name: 'compCity',
    title: 'Company City',
  },
  {
    name: 'compState',
    title: 'Company State',
  },
  {
    name: 'compCountry',
    title: 'Company Country',
  },
  {
    name: 'compZipcode',
    title: 'Company Zipcode',
  },
  {
    name: 'compGlobalRegions',
    title: 'Company Global Region',
  },
  {
    name: 'compWebsite',
    title: 'Company Website',
  },
  {
    name: 'compParentName',
    title: 'Parent Company',
  },
  {
    name: 'compNationalParentName',
    title: 'National Parent Company',
  },
  {
    name: 'compWorldwideParentName',
    title: 'Worldwide Parent Company',
  },
  {
    name: 'compEntityType',
    title: 'Entity Type',
  },
  {
    name: 'compGlobalHqParentGuid',
    title: 'Worldwide Parent GUID',
  },
  {
    name: 'compRevRange',
    title: 'Global Revenue Range',
  },
  {
    name: 'compEmpCount',
    title: 'Global Employee Count',
  },
  {
    name: 'compEmpRange',
    title: 'Global Employee Range',
  },
  {
    name: 'compIndustry',
    title: 'Industry',
  },
  {
    name: 'compSicCodes',
    title: 'SIC Codes',
  },
  {
    name: 'compSicDescription',
    title: 'SIC Description',
  },
  {
    name: 'compNaicsCodes',
    title: 'NAICS Codes',
  },
  {
    name: 'compNaicsDescription',
    title: 'NAICS Description',
  },
  {
    name: 'compIsSubsidiary',
    title: 'Is Subsidiary',
  },
  {
    name: 'compLegalStatus',
    title: 'Company Type',
  },
  {
    name: 'compFoundingYear',
    title: 'Year Of Founding',
  },
  {
    name: 'compPubProfileUrl',
    title: 'Company Linkedin Url',
  },
  {
    name: 'conGUID',
    title: 'Contact GUID',
  },
  {
    name: 'conFullName',
    title: 'Full Name',
  },
  {
    name: 'conFirstName',
    title: 'First Name',
  },
  {
    name: 'conMiddleName',
    title: 'Middle Name',
  },
  {
    name: 'conLastName',
    title: 'Last Name',
  },
  {
    name: 'conEmail',
    title: 'Contact Email',
  },
  {
    name: 'emailDeliverabilityScore%',
    title: 'Email Deliverability Score%',
  },
  {
    name: 'conJobTitleEn',
    title: 'Job Title',
  },
  {
    name: 'conLevels',
    title: 'Contact Level',
  },
  {
    name: 'conFunctions',
    title: 'Contact Function',
  },
  {
    name: 'conSubFunctions',
    title: 'Contact Sub Function',
  },
  {
    name: 'conJobUpdatedOn',
    title: 'Job Updated On',
  },
  {
    name: 'conJobStartedOn',
    title: 'Job Started On',
  },
  {
    name: 'conWorkLocAddr',
    title: 'Contact Work Location Address',
  },
  {
    name: 'conWorkLocCity',
    title: 'Contact Work Location City',
  },
  {
    name: 'conWorkLocState',
    title: 'Contact Work Location State',
  },
  {
    name: 'conWorkLocCountry',
    title: 'Contact Country',
  },
  {
    name: 'conWorkLocZipcode',
    title: 'Contact Work Location Zipcode',
  },
  {
    name: 'conWorkLocPhoneNo',
    title: 'Contact Work Location Phone',
  },
  {
    name: 'conHeadline',
    title: 'Contact Headline',
  },
  {
    name: 'conLoc',
    title: 'Contact Location',
  },
  {
    name: 'conPubProfileUrl',
    title: 'Contact Linkedin Url',
  },
  {
    name: 'compAllIndustry',
    title: 'All Industry',
  },
  {
    name: 'compAllSicCodes',
    title: 'All SIC Codes',
  },
  {
    name: 'compAllSicDescription',
    title: 'All SIC Description',
  },
  {
    name: 'compAllNaicsCodes',
    title: 'All NAICS Codes',
  },
  {
    name: 'compAllNaicsDescription',
    title: 'All NAICS Description',
  },
  {
    name: 'smarteTransactionId',
    title: 'Smarte Transaction ID',
  },
  {
    name: 'cgEmail',
    title: 'Client Given Email',
  },
  {
    name: 'cgEmailDeliverabilityScore%',
    title: 'Client Given Email Deliverability Score%',
  },
  {
    name: 'directDial1',
    title: 'Direct Dial 1',
  },
  {
    name: 'directDial2',
    title: 'Direct Dial 2',
  },
];
